// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="FAQ zu Fördergelder Wärmepumpe"
        meta={[
          { name: 'description', content: 'Fördergelder für eine Wärmepumpe: Wichtigste Fragen ➤ Förderlandschaft ✔️ Richtige Beantragung ✔️ Vorgehen pro Kanton ✔️ Jetzt hier informieren!' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='KB1G0tadoHm5orNSbTRd0-5WqxEVV7eVFkRDXhml4Vhe'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "5WqxEVV7eVFkRDXhml4Vhe",
    "updatedAt": "2023-04-20T16:09:56.043Z",
    "title": "Häufig gestellte Fragen zum Thema Fördergelder Wärmepumpe",
    "keyword": "Häufig gestellte Fragen",
    "articleType": "FAQ",
    "description": "Alles, was Sie schon immer über über das Thema Fördergelder Wärmepumpe in der Schweiz wissen wollten!",
    "content": [
        {
            "type": "FAQ",
            "id": "bdWk3vG8Qh5ijmV5hP3CK",
            "updatedAt": "2020-03-20T12:10:27.437Z",
            "title": "Förderlandschaft Schweiz",
            "question": "<p>Wie funktioniert die Förderlandschaft in der Schweiz?</p>\n",
            "answer": "<p>Aus einem Grundsatzentscheid des Bundes für einen schrittweisen Ausstieg aus der Kernenergie sind diverse Förderprogramme entstanden, sodass mittlerweile oft von einem “Förderdschungel” die Rede ist. Wir bringen Licht ins Dunkel.</p>\n<p>Grundsätzlich sind die Förderprogramme Angelegenheit der Kantone. Der Bund leistet Globalbeiträge an die Förderprogramme der Kantone. Das wichtigste Instrument in diesem Bereich ist das <a href=\"https://www.dasgebaeudeprogramm.ch/de/\" title=\"www.dasgebaeudeprogramm.ch\">Gebäudeprogramm</a>. Es unterstützt in der ganzen Schweiz die Verbesserung der Wärmedämmung von Einfamilienhäusern, Mehrfamilienhäusern oder Dienstleistungsgebäude. Gemeinden können ergänzend zu den Förderprogrammen der Kantone weitere Fördergelder ausschütten.</p>\n<p>Unabhängig von den kantonalen Förderstellen, unterstützt die Stiftung myClimate Bauherrschaften mit 2’000 Franken, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen. Es werden nur Wärmepumpen gefördert, die zum Zeitpunkt der Anmeldung keine anderen Fördergelder von Kanton oder Gemeinde beziehen können.</p>\n<p>Wollen Sie herausfinden, welche Förderbeiträge Ihnen an Ihrem Wohnsitz zur Verfügung stehen? Bei <a href=\"https://www.energiefranken.ch\" title=\"www.energiefranken.ch\">Energiefranken</a> können Sie dies unkompliziert abfragen.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "5ekhZrdmrBxd4wpQGIld9X",
            "updatedAt": "2020-03-20T12:10:55.775Z",
            "title": "Wie erhalte ich meine Fördergelder?",
            "question": "<p>Wie erhalte ich meine Fördergelder?</p>\n",
            "answer": "<p>Abhängig von Ihrer bestehenden Heizung, von Ihrem Wohnort, von Ihrer neuen Heizung und weiteren Kriterien, ist das Vorgehen unterschiedlich. Auf unserer Website finden Sie eine Übersicht pro Kanton. Das Vorgehen variert pro Kanton respektive Förderstelle.</p>\n<p>Gerne kümmern wir uns um Ihre Fördergelder. Lassen Sie sich am besten noch heute beraten und realisieren Sie Ihr Umbauprojekt mit uns!</p>\n<p>Wir erstellen in der Regel die nötigen Dokumente für den Erhalt der\nFördergelder. Die Fördergelder werden ihnen nach Projektabschluss direkt\nausbezahlt.</p>\n<p><em>Wichtiger Hinweis:</em>\nBei gewissen Förderprogrammen ist es nötig, diese vor\nUmbaubeginn oder sogar vor Auftragsvergabe einzureichen. Bitte sprechen Sie\nsich mit unserem Berater diesbezüglich ab.</p>\n"
        },
        {
            "type": "FAQ",
            "id": "2OGgO7e7SfZXfwkle4rSAM",
            "updatedAt": "2020-03-20T12:11:15.365Z",
            "title": "Beantragungsstelle Fördergelder",
            "question": "<p>Wo beantrage ich die Fördergelder für meinen Heizungsersatz?</p>\n",
            "answer": "<p>Abhängig von Ihrer bestehenden Heizung, von Ihrem Wohnort, von Ihrer neuen Heizung und weiteren Kriterien, kann die Förderstelle varieren. Auf unserer Website finden Sie eine Übersicht pro Kanton.</p>\n<p>Gerne kümmern wir uns um Ihre Fördergelder. Lassen Sie sich am besten noch heute beraten und realisieren Sie Ihr Umbauprojekt mit uns!</p>\n<p>Wir erstellen in der Regel die nötigen Dokumente für den Erhalt der\nFördergelder. Die Fördergelder werden ihnen nach Projektabschluss direkt\nausbezahlt.</p>\n<p><em>Wichtiger Hinweis:</em>\nBei gewissen Förderprogrammen ist es nötig, diese vor\nUmbaubeginn oder sogar vor Auftragsvergabe einzureichen. Bitte sprechen Sie\nsich mit unserem Berater diesbezüglich ab.</p>\n"
        }
    ],
    "topic": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "previewImage": {
        "description": "Heizungsmacher - häufig gestellte Fragen",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/35SWWHYijCZ09BkUucaY7N/a83c972b34abd14bdf280e92dd70f1d8/faq-placeholder-img.png"
    },
    "previousArticle": "/foerdergelder/das-gebaeudeprogramm",
    "nextArticle": "/foerdergelder/kanton-aargau"
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
